<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <cuenta />

    </b-tab>

    <!-- change password tab -->
    <b-tab>

      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Cambiar Contraseña</span>
      </template>

      <cambiarPassword />

    </b-tab>

    <!-- info -->
    <b-tab>

      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Alarmas</span>
      </template>

      <alarmas />

    </b-tab>

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

const cuenta = () => import('@/layouts/components/Navbar/Configuraciones/Cuenta/Cuenta.vue')
const cambiarPassword = () => import('@/layouts/components/Navbar/Configuraciones/CambiarPassword/CambiarPassword.vue')
const alarmas = () => import('@/layouts/components/Navbar/Configuraciones/Alarmas/Alarmas.vue')

export default {
  components: {
    BTabs,
    BTab,

    cuenta,
    cambiarPassword,
    alarmas,
  },
  data() {
    return {
      options: {},
      userData: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getUsuarios: 'usuarios/getUsuarios',
    }),
  },
  watch: {
    getUsuarios() {
      this.userData = this.getUsuarios.find(u => u.id === this.user.id)
    },
  },
}
</script>
